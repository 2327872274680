@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

* {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.menu ul.lang_ul {
  display: block;
}

.menu .menu_nav ul.lang_ul {
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  display: block;
  box-shadow: 1px 1px 10px 1px #eaeaea;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.menu .menu_nav ul.lang_ul li {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 30px;
}

svg {
  transition: 0s;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -ms-transition: 0s;
  -o-transition: 0s;
}

body {
  font-family: 'Rubik', sans-serif;
}

/* .main {
  padding-top: 100px;
} */

.container {
  max-width: 1150px;
  margin: 0 auto;
}

.container_header {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 24px;
  padding-top: 58px;
}

.header {
  border-bottom: 1px solid #f2f2f2;
  background: white;
  z-index: 1000;
  position: relative;
}

.header .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
}

.header .header_left p {
  color: #555;
  font-size: 12px;
}

.header .header_left span {
  font-size: 14px;
  color: #1e3685;
  opacity: 1;
}

.header .header_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header_right_lang ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.header .header_right_lang ul a {
  padding-left: 13px;
  padding-right: 13px;
  font-size: 12px;
  opacity: 0.5;
}

.header .header_right_lang ul a.active {
  opacity: 1;
}

.header .header_right_info ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.header .header_right_info ul li {
  padding-left: 23px;
  font-size: 12px;
}

.header .header_right_info .header_right_info_mail {
  color: #1e3685;
  display: flex;
  align-items: center;
}

.header .header_right_info .header_right_info_location {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.header .header_right_info .header_right_info_location svg {
  padding-right: 4px;
}

.header .header_right_info .header_right_info_mail svg {
  padding-right: 4px;
}

.menu {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0px;
  z-index: 500;
  background: white;
}

.menu .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.menu .menu_logo {
  display: flex;
  align-items: center;
}

.menu .menu_logo img {
  width: 38px;
  height: 38px;
}

.menu .menu_logo div {
  padding-left: 10px;
  line-height: 16px;
}

.menu .menu_logo p {
  font-size: 16px;
  font-weight: 500;
}

.menu .menu_logo span {
  opacity: 0.5;
  font-size: 12px;
}

.menu .menu_nav ul {
  display: flex;
  list-style-type: none;
}

.menu .menu_nav ul li:hover .sub_menu {
  display: flex;
}

.menu .menu_nav ul li a:hover {
  color: #1e3685;
}

.menu .menu_nav ul li a.active {
  color: #1e3685;
}

.menu .menu_nav ul li {
  padding-top: 23px;
  padding-bottom: 23px;
}

.menu .menu_nav .sub_menu ul {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #f2f2f2;
  width: 100vw;
}

.menu .menu_nav .sub_menu {
  display: none;
  width: 100%;
  margin-top: 20px;

  position: absolute;
  background: white;
  box-shadow: 1px 10px 10px 1px #eaeaea;
  right: 0;

  /* margin-top: 30px; */
}

.menu .menu_nav .sub_menu li a {
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  font-size: 14px;
  opacity: 0.9;
  padding-top: 22px;
  padding-bottom: 22px;
}

.menu .menu_nav .sub_menu li {
  padding-top: 0;
  padding-bottom: 0;
}

.menu .menu_nav ul li a {
  font-size: 13px;
  padding-left: 33px;
  display: flex;
  align-items: center;
  color: #303030;
}

.menu .menu_nav ul li a svg {
  position: relative;
  left: 8px;
  font-size: 11px;
  opacity: 0.5;
}

.footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #1e3685;
  position: relative;
  z-index: 2000;
}

.footer .footer_logo {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.footer .footer_logo img {
  width: 38px;
  height: 38px;
  filter: brightness(0) invert(1);
}

.footer .footer_logo div {
  padding-left: 10px;
  line-height: 16px;
  color: white;
}

.header_left a {
  color: #1e3685;
}

.header_right_info_mail a {
  color: #1e3685;
}

.menu_logo {
  color: #303030;
}

.footer .row {
  display: flex;
  justify-content: space-between;
}

.footer .col {
  width: 24%;
}

.mr-100 {
  margin-right: 100px;
}

.footer .col .col_header {
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}

.footer .footer_logo p {
  font-size: 16px;
  font-weight: 500;
}

.footer .footer_logo span {
  opacity: 0.5;
  font-size: 12px;
}

.footer .col ul {
  list-style: none;
}

.footer .col ul li a {
  font-size: 14px;
  opacity: 0.5;
  color: white;
}

.footer .col ul li {
  padding-top: 6px;
  padding-bottom: 6px;
}

.footer .col p {
  color: white;
  font-size: 14px;
  padding-top: 10px;
  padding-right: 50px;
  width: 300px;
}

.footer .realsoft_logo {
  width: 92px;
  height: 10px;
  padding-top: 20px;
}

.home .main_item {
  padding-right: 20px;
}

.home .main_item a {
  color: black;
}

.home .main_item a:hover {
  color: #790000;
  text-decoration: underline;
}

.home .main_item img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.home .main_item p {
  font-size: 24px;
  padding-top: 15px;
  line-height: 36px;
  color: #303030;
  text-align: center;
}

.home .main_item span {
  color: #1e3685;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  display: block;
  padding-top: 10px;
}

.home {
  padding-bottom: 130px;
}

.home .row {
  display: flex;
  justify-content: space-between;
}

.home .row .col {
  width: 33%;
}

.home .news {
  border-left: 1px solid #f2f2f2;
  padding-left: 38px;
  padding-right: 38px;
}

.home .news .item:last-child {
  border-bottom: 0px solid #f2f2f2;
}

.home .news .item {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.home .news .item a {
  color: black;
  text-decoration: none;
}

.home .news .item:hover a {
  color: #790000;
  text-decoration: underline;
}

.home .news .item p {
  font-size: 17px;
  line-height: 23px;
  padding-bottom: 8px;
}

.home .news .item span {
  color: #1e3685;
  text-transform: uppercase;
  font-size: 14px;
}

.page {
  padding-top: 28px;
  min-height: calc(100vh - 250px);
}

.page .container img {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
}

.page .container .page_category {
  max-width: 780px;
  margin: 0 auto;
  color: #1e3685;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 14px;
  padding-top: 30px;
}

.page .container .page_title {
  max-width: 780px;
  margin: 0 auto;
  color: #000;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 12px;
  line-height: 50px;
}

.page .container .page_content img {
  height: 350px;
  object-fit: cover;
}

.page .container .page_content span {
  max-width: 780px;
  margin: 0 auto;
  color: #303030;
  font-size: 18px;
  line-height: 30px;
  display: block;
  opacity: 0.5;
  margin-bottom: 29px;
}

.page_block {
  border-bottom: 1px solid #eaeaea;
}

.page_block a {
  color: black;
}

.page .container .page_content span.m0 {
  margin-bottom: 0px;
}

.page .container .page_content p {
  max-width: 780px;
  margin: 0 auto;
  color: #303030;
  font-size: 18px;
  line-height: 33px;
  opacity: 0.9;
}

.page .container .page_date {
  padding-top: 28px;
  padding-bottom: 60px;
  max-width: 780px;
  margin: 0 auto;
  color: black;
  opacity: 0.5;
  font-size: 18px;
  line-height: 30px;
}

.report {
  padding-top: 50px;
}

.report .row {
  max-width: 580px;
  margin: 0 auto;
}

.report .row .repoert_title {
  font-size: 26px;
  color: #303030;
  padding-bottom: 18px;
  font-weight: 500;
}

.report .row form textarea {
  font-size: 16px;
  padding: 20px;
  width: 100%;
  border: 1px solid #c6c8cb;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.3);
  font-family: 'Rubik', sans-serif;
}

.report .row form .from_check {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: black;
  padding-top: 18px;
}

.report .row form .from_check p {
  padding-left: 10px;
}

.report .row form .from_check span {
  color: #1e3685;
  text-decoration: underline;
}

.report .row form .form_bottom {
  padding-top: 36px;
  padding-bottom: 105px;
}

.report .row form .form_bottom button {
  padding: 14px 30px;
  margin-right: 18px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.report .row form .form_bottom .form_button_send {
  background: #1e3685;
  color: white;
  border: 0px;
}

.report .row form .form_bottom .form_button_cancel {
  background: white;
  color: black;
  border: 1px solid #c6c8cb;
}

.navigation {
  padding-top: 40px;
  width: 100%;
  position: absolute;
  background: white;
  z-index: 100;
  box-shadow: 0px 100px 20px rgba(0, 0, 0, 0.1);
}

.navigation .navigation_search input {
  border: 0px;
  border-bottom: 1px solid #dedede;
  height: 36px;
  width: 100%;
  font-size: 16px;
}

.navigation .row {
  display: flex;
  flex-wrap: wrap;
}

.navigation .row .col {
  width: 25%;
  padding-top: 45px;
}

.navigation .row .col p {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 16px;
}

.navigation .row .col ul {
  list-style: none;
}

.navigation .row .col li {
  padding-top: 6px;
  padding-bottom: 6px;
}

.navigation .row .col li a {
  font-size: 14px;
  color: #303030;
}

.navigation .row .col li a.active {
  color: #1e3685;
}

.navigation .navigation_bottom {
  padding-top: 40px;
  padding-bottom: 27px;
  display: flex;
  justify-content: center;
}

.navigation .navigation_bottom a {
  color: #1e3685;
}

.slider {
  height: 510px;
  color: white;
  z-index: 10;
  position: relative;
}

.slider a {
  color: white;
  position: relative;
}

.slider .container {
  display: flex;
  height: 510px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.slider_left {
  padding-bottom: 120px;
  width: 50%;
}

.slider_left h1 {
  font-size: 36px;
  padding-bottom: 13px;
}

.slider_right ul {
  padding-bottom: 120px;
  margin-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 13px;
}

.video_mackop {
  background: linear-gradient(180deg, rgba(7, 15, 40, 0.43) 0%, #020a24 94.51%);
  height: 546px;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 3;
}

.video {
  z-index: 2;
  position: absolute;
  top: 0;
}

.video video {
  background: linear-gradient(180deg, rgba(7, 15, 40, 0.43) 0%, #020a24 94.51%);
  height: 546px;
  width: 100vw;
  object-fit: cover;
}

.slider_right ul li {
  margin-top: -6px;
  padding-bottom: 26px;
}

.slider_right {
  width: 50%;
  display: flex;
}

.slider_left p {
  opacity: 0.6;
}

/* dark  */

#dark_header {
  background: #09153e;
  border: 0px;
}

#dark_header .header_left p {
  color: white;
}

#dark_header .header_right_info_mail a {
  color: white;
}

#dark_header .header_right_info_mail svg {
  color: white;
}

#dark_header .header_left a {
  color: white;
}

#dark_header .header_right_info .header_right_info_location svg {
  color: white;
}

#dark_header .header_right_info .header_right_info_location {
  color: white;
}

#dark_header .header_right_lang ul a {
  color: white;
}

#dark_menu {
  background: transparent;
  color: white;
}

#dark_menu .menu_nav ul.lang_ul {
  background: transparent;
}

#dark_menu .menu_nav ul li a {
  color: white;
  font-size: 13px;
}

#dark_menu .menu_logo p {
  color: white;
}

.lang {
  position: absolute;
}

#dark_menu .menu_logo span {
  color: white;
}

.menu_button svg {
  color: rgb(48, 48, 48);
}

#dark_menu .menu_button svg {
  color: white;
}

#dark_menu .menu_logo img {
  filter: brightness(0) invert(1);
}

#dark_menu .sub_menu {
  background: transparent;
  border: 0px;
  box-shadow: 0px 0px;
}

#dark_menu .menu_nav .sub_menu ul {
  border: 0px;
}

.media .col-6 {
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(50% - 15px);
}

.media .item_big {
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  position: relative;
  width: 100%;
  height: 333px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
}

.media_img_bg {
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  position: absolute;
  top: 0;
  width: 100%;
  height: 333px;
  z-index: 1;
}

.media .item {
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  width: 100%;
  height: 159px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.media_img {
  position: absolute;
  width: 100%;
  height: 333px;
  object-fit: cover;
}

.media_img_bg_2 {
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  background: linear-gradient(
    180deg,
    rgba(30, 54, 133, 0.32) 0%,
    #0d2164 94.51%
  );
  position: absolute;
  top: 0;
  width: 100%;
  height: 159px;
  z-index: 1;
}

.media_img_2 {
  position: absolute;
  width: 100%;
  height: 159px;
  object-fit: cover;
}

.media .item_big .text {
  padding: 32px;
  position: relative;
  z-index: 3;
}

.media .item_big .text p {
  font-size: 17px;
  line-height: 23.8px;
}

.media .item_big .text span {
  opacity: 0.6;
  padding-top: 8px;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}

.media .item .play {
  width: 44px;
  height: 44px;
  position: relative;
  z-index: 4;
}

.media .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal_coutom {
  padding: 0;
}

.modal_content {
  width: 100%;
}

.modal_content video {
  width: 100%;
  height: 500px;
}
